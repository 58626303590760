import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { FlexRow } from "@/shared/globals";
import { FaceBook } from "@/assets/Icons/FaceBook";
import { Instagram } from "@/assets/Icons/Instagram";
import { WhatsApp } from "@/assets/Icons/WhatsApp";
import { SnapChat } from "@/assets/Icons/SnapChat";
import { Youtube } from "@/assets/Icons/Youtube";
import { LinkedIn } from "@/assets/Icons/LinkedIn";
import { Twitter } from "@/assets/Icons/Twitter";
import { Telegram } from "@/assets/Icons/Telegram";
import { Pinterest } from "@/assets/Icons/Pinterest";
import { Tiktok } from "@/assets/Icons/Tiktok";

import { StoreContextType } from "@/lib/storeData/StoreContext";

const socialIcons = {
  facebook: <FaceBook />,
  instagram: <Instagram />,
  whatsapp: <WhatsApp />,
  snapchat: <SnapChat />,
  youtube: <Youtube />,
  twitter: <Twitter />,
  linkedin: <LinkedIn />,
  telegram: <Telegram />,
  pinterest: <Pinterest />,
  tiktok: <Tiktok />,
};

interface SocialLinksProps {
  socialSettings: StoreContextType["socialMedia"];
  padding?: CSSProperties["padding"];
  isCircle?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  className?: string;
}

const SocialLinks = ({
  socialSettings,
  padding,
  isCircle = true,
  justifyContent = "start",
  className,
}: SocialLinksProps) => {
  return (
    <StyledFlexRow
      data-test="footer-social-links-list"
      flexWrap="wrap"
      style={{ padding }}
      justifyContent={justifyContent}
      className={className}
    >
      {socialSettings?.map((social, i) => {
        const link = social?.link?.startsWith?.("http")
          ? social.link
          : `http://${social?.link}`;
        return (
          social?.link && (
            <Circle
              key={i}
              href={link}
              title={social.name!}
              data-test="footer-social-link-item"
              target="_blank"
              rel="noreferrer"
              isCircle={isCircle}
            >
              {socialIcons[social.name!]}
            </Circle>
          )
        );
      })}
    </StyledFlexRow>
  );
};

export { SocialLinks };

const StyledFlexRow = styled(FlexRow)`
  gap: 8px;
`;

const Circle = styled.a<{ isCircle?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isCircle }) =>
    isCircle &&
    css`
      width: 2.25rem;
      height: 2.25rem;
      border: solid 1px #d0d5dd;
      border-radius: 50%;
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    `}
`;
