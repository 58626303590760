const Tiktok = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0199 1.25H9.98007C5.15859 1.25 1.25 5.15859 1.25 9.98007V10.0199C1.25 14.8414 5.15859 18.75 9.98007 18.75H10.0199C14.8414 18.75 18.75 14.8414 18.75 10.0199V9.98007C18.75 5.15859 14.8414 1.25 10.0199 1.25Z"
        fill="#1D2939"
      />
      <path
        d="M14.2306 7.581V9.23333C13.9418 9.20513 13.5666 9.13953 13.1515 8.98748C12.6095 8.78883 12.2061 8.51722 11.9419 8.30692V11.6465L11.9351 11.6361C11.9394 11.7023 11.9419 11.7698 11.9419 11.8378C11.9419 13.4963 10.5924 14.8464 8.93334 14.8464C7.27426 14.8464 5.9248 13.4963 5.9248 11.8378C5.9248 10.1794 7.27426 8.82868 8.93334 8.82868C9.09581 8.82868 9.25522 8.84156 9.41095 8.86669V10.4951C9.26135 10.4418 9.10071 10.413 8.93334 10.413C8.14794 10.413 7.50847 11.0518 7.50847 11.8378C7.50847 12.6238 8.14794 13.2627 8.93334 13.2627C9.71873 13.2627 10.3582 12.6232 10.3582 11.8378C10.3582 11.8084 10.3576 11.779 10.3558 11.7495V5.25977H12.0069C12.013 5.39955 12.0185 5.54057 12.0246 5.68036C12.0357 5.95565 12.1338 6.2199 12.3048 6.43632C12.5053 6.69076 12.8014 6.98628 13.2171 7.22233C13.6065 7.44244 13.9719 7.53747 14.2306 7.58223V7.581Z"
        fill="white"
      />
    </svg>
  );
};

export { Tiktok };
