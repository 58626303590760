const Twitter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7488 10.0003C18.7488 14.4196 15.473 18.0731 11.2176 18.666C10.8197 18.7212 10.4126 18.75 9.99939 18.75C9.5224 18.75 9.054 18.712 8.59787 18.6384C4.43193 17.9677 1.25 14.3552 1.25 10.0003C1.25 5.16777 5.16763 1.25 10 1.25C14.8324 1.25 18.75 5.16777 18.75 10.0003H18.7488Z"
        fill="#1D2939"
      />
      <path
        d="M4.79955 5.10938L8.8349 10.5047L4.77441 14.8915H5.68853L9.24383 11.051L12.1162 14.8915H15.2263L10.9642 9.19269L14.7438 5.10938H13.8297L10.5558 8.64641L7.91036 5.10938H4.80016H4.79955ZM6.14344 5.78257H7.57194L13.8812 14.2184H12.4527L6.14344 5.78257Z"
        fill="white"
      />
    </svg>
  );
};

export { Twitter };
