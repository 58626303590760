const Youtube = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10Z"
        fill="#1D2939"
      />
      <path
        d="M15.5091 8.18627C15.4403 7.52161 15.2921 6.78686 14.7467 6.40073C14.3243 6.1013 13.7691 6.09023 13.2508 6.09084C12.1551 6.09084 11.0588 6.09269 9.96313 6.0933C8.90927 6.09453 7.8554 6.09515 6.80154 6.09638C6.3613 6.09638 5.93336 6.06256 5.52448 6.25316C5.1734 6.41672 4.89856 6.72783 4.73316 7.074C4.50382 7.55543 4.45587 8.1008 4.4282 8.63327C4.37717 9.60289 4.3827 10.575 4.44357 11.544C4.48845 12.2511 4.6022 13.0326 5.14881 13.4832C5.63331 13.8823 6.31703 13.902 6.94541 13.9026C8.94 13.9044 10.9352 13.9063 12.9304 13.9075C13.1862 13.9081 13.453 13.9032 13.7137 13.8749C14.2265 13.8196 14.7153 13.6726 15.0449 13.2926C15.3775 12.9096 15.463 12.3765 15.5134 11.8717C15.6364 10.6469 15.6352 9.41044 15.5091 8.18627ZM8.80966 11.7162V8.28218L11.7831 9.99886L8.80966 11.7162Z"
        fill="white"
      />
    </svg>
  );
};

export { Youtube };
