const FaceBook = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7488 10.0003C18.7488 14.4196 15.473 18.0731 11.2176 18.666C10.8197 18.7212 10.4126 18.75 9.99939 18.75C9.5224 18.75 9.054 18.712 8.59787 18.6384C4.43193 17.9677 1.25 14.3552 1.25 10.0003C1.25 5.16777 5.16764 1.25 10 1.25C14.8324 1.25 18.75 5.16777 18.75 10.0003H18.7488Z"
        fill="#1D2939"
      />
      <path
        d="M11.2182 8.27604V10.1822H13.5761L13.2027 12.7499H11.2182V18.6658C10.8203 18.721 10.4132 18.7498 9.99997 18.7498C9.52299 18.7498 9.05459 18.7118 8.59845 18.6382V12.7499H6.42383V10.1822H8.59845V7.84993C8.59845 6.40299 9.77129 5.22949 11.2188 5.22949V5.23072C11.2231 5.23072 11.2268 5.22949 11.2311 5.22949H13.5767V7.45018H12.044C11.5885 7.45018 11.2188 7.81989 11.2188 8.27543L11.2182 8.27604Z"
        fill="white"
      />
    </svg>
  );
};

export { FaceBook };
