const Telegram = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0199 1.25H9.98007C5.15858 1.25 1.25 5.15859 1.25 9.98007V10.0199C1.25 14.8414 5.15858 18.75 9.98007 18.75H10.0199C14.8414 18.75 18.75 14.8414 18.75 10.0199V9.98007C18.75 5.15859 14.8414 1.25 10.0199 1.25Z"
        fill="#1D2939"
      />
      <path
        d="M5.96018 9.89675C5.97855 9.88565 5.99693 9.8751 6.01484 9.86511C6.32625 9.69085 6.64178 9.52768 6.95686 9.36451C6.97385 9.36451 7.00232 9.34064 7.0184 9.33287C7.04274 9.32011 7.06709 9.3079 7.09143 9.29513C7.13828 9.27071 7.18513 9.24685 7.23152 9.22243C7.32521 9.17414 7.41845 9.12586 7.51214 9.07757L8.07294 8.78731C8.44681 8.59417 8.82114 8.40047 9.19501 8.20734C9.56888 8.0142 9.9432 7.8205 10.3171 7.62736C10.6909 7.43422 11.0653 7.24053 11.4391 7.04739C11.813 6.85425 12.1873 6.66056 12.5612 6.46742C12.6443 6.42413 12.7343 6.35975 12.8234 6.34088C12.8983 6.32478 12.9713 6.2937 13.0467 6.2765C13.1895 6.24375 13.347 6.23043 13.4839 6.30203C13.5312 6.327 13.5749 6.36197 13.6111 6.40581C13.7848 6.61338 13.7604 6.95415 13.7237 7.24608C13.4678 9.2807 13.212 11.3159 12.9557 13.3505C12.9208 13.6297 12.873 13.936 12.6907 14.1125C12.5364 14.2618 12.3168 14.2785 12.1262 14.2152C11.9356 14.1514 11.7675 14.0176 11.6026 13.8861C10.9187 13.3388 10.2344 12.7916 9.5505 12.2444C9.38791 12.1145 9.20695 11.9447 9.20879 11.7088C9.20971 11.5667 9.27998 11.4402 9.35163 11.3275C9.94595 10.3907 10.8035 9.7469 11.4414 8.85335C11.5314 8.72737 11.6022 8.49982 11.4786 8.42712C11.4051 8.38383 11.3206 8.44265 11.2536 8.49871C10.4103 9.20633 9.5675 9.91451 8.72423 10.6221C8.44911 10.853 8.16067 11.0906 7.82906 11.1472C7.53236 11.1982 7.23382 11.0983 6.94676 10.9962C6.70609 10.9107 6.46587 10.823 6.22657 10.7326C6.09935 10.6848 5.96799 10.6332 5.8697 10.525C5.77141 10.4168 5.71492 10.2347 5.77417 10.0904C5.81137 9.99998 5.88349 9.94281 5.95927 9.89619L5.96018 9.89675Z"
        fill="white"
      />
    </svg>
  );
};

export { Telegram };
