const LinkedIn = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0199 1.25H9.98008C5.15859 1.25 1.25 5.15859 1.25 9.98007V10.0199C1.25 14.8414 5.15859 18.75 9.98008 18.75H10.0199C14.8414 18.75 18.75 14.8414 18.75 10.0199V9.98007C18.75 5.15859 14.8414 1.25 10.0199 1.25Z"
        fill="#1D2939"
      />
      <path
        d="M5.42417 7.06699C5.19371 6.85302 5.0791 6.58816 5.0791 6.27302C5.0791 5.95788 5.19433 5.68137 5.42417 5.46678C5.65464 5.2528 5.95129 5.14551 6.31476 5.14551C6.67823 5.14551 6.96325 5.2528 7.19309 5.46678C7.42356 5.68075 7.53817 5.94991 7.53817 6.27302C7.53817 6.59613 7.42294 6.85302 7.19309 7.06699C6.96263 7.28097 6.67026 7.38826 6.31476 7.38826C5.95926 7.38826 5.65464 7.28097 5.42417 7.06699ZM7.34449 8.29444V14.8547H5.27217V8.29444H7.34449Z"
        fill="white"
      />
      <path
        d="M14.2448 8.94276C14.6967 9.43325 14.9223 10.1065 14.9223 10.9636V14.7391H12.9536V11.2297C12.9536 10.7974 12.8415 10.4614 12.6177 10.2223C12.3939 9.98321 12.0922 9.86304 11.7146 9.86304C11.3369 9.86304 11.0352 9.9826 10.8114 10.2223C10.5876 10.4614 10.4754 10.7974 10.4754 11.2297V14.7391H8.49512V8.27631H10.4754V9.13344C10.6759 8.84773 10.9463 8.62211 11.286 8.45596C11.6257 8.2898 12.0076 8.20703 12.4325 8.20703C13.1891 8.20703 13.7936 8.45227 14.2448 8.94215V8.94276Z"
        fill="white"
      />
    </svg>
  );
};

export { LinkedIn };
