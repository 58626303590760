const Instagram = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0199 1.25H9.98007C5.15859 1.25 1.25 5.15859 1.25 9.98007V10.0199C1.25 14.8414 5.15859 18.75 9.98007 18.75H10.0199C14.8414 18.75 18.75 14.8414 18.75 10.0199V9.98007C18.75 5.15859 14.8414 1.25 10.0199 1.25Z"
        fill="#1D2939"
      />
      <path
        d="M12.7071 4.81055H7.29211C5.79612 4.81055 4.5791 6.02757 4.5791 7.52356V12.4775C4.5791 13.9735 5.79612 15.1905 7.29211 15.1905H12.7071C14.2031 15.1905 15.4201 13.9735 15.4201 12.4775V7.52356C15.4201 6.02757 14.2031 4.81055 12.7071 4.81055ZM5.53617 7.52356C5.53617 6.55546 6.32401 5.76761 7.29211 5.76761H12.7071C13.6752 5.76761 14.463 6.55546 14.463 7.52356V12.4775C14.463 13.4456 13.6752 14.2334 12.7071 14.2334H7.29211C6.32401 14.2334 5.53617 13.4456 5.53617 12.4775V7.52356Z"
        fill="white"
      />
      <path
        d="M9.99915 12.5237C11.3903 12.5237 12.5227 11.3919 12.5227 10.0001C12.5227 8.60836 11.3909 7.47656 9.99915 7.47656C8.60739 7.47656 7.47559 8.60836 7.47559 10.0001C7.47559 11.3919 8.60739 12.5237 9.99915 12.5237ZM9.99915 8.43424C10.863 8.43424 11.5656 9.13686 11.5656 10.0007C11.5656 10.8646 10.863 11.5672 9.99915 11.5672C9.13528 11.5672 8.43265 10.8646 8.43265 10.0007C8.43265 9.13686 9.13528 8.43424 9.99915 8.43424Z"
        fill="white"
      />
      <path
        d="M12.7569 7.8843C13.1314 7.8843 13.4366 7.57958 13.4366 7.20435C13.4366 6.82913 13.132 6.52441 12.7569 6.52441C12.3818 6.52441 12.0771 6.82913 12.0771 7.20435C12.0771 7.57958 12.3818 7.8843 12.7569 7.8843Z"
        fill="white"
      />
    </svg>
  );
};

export { Instagram };
