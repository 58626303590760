const WhatsApp = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0199 1.25H9.98007C5.15859 1.25 1.25 5.15859 1.25 9.98007V10.0199C1.25 14.8414 5.15859 18.75 9.98007 18.75H10.0199C14.8414 18.75 18.75 14.8414 18.75 10.0199V9.98007C18.75 5.15859 14.8414 1.25 10.0199 1.25Z"
        fill="#1D2939"
      />
      <path
        d="M11.705 12.5597C9.46535 12.5597 7.64319 10.7369 7.64258 8.49719C7.64319 7.92945 8.10548 7.46777 8.67199 7.46777C8.73024 7.46777 8.78786 7.47268 8.84304 7.48249C8.96444 7.50272 9.07971 7.5438 9.18578 7.60572C9.20111 7.61492 9.21153 7.62964 9.21398 7.6468L9.45064 9.1385C9.4537 9.15567 9.44819 9.17345 9.43654 9.18632C9.30595 9.33102 9.13918 9.43524 8.95341 9.48736L8.8639 9.5125L8.89761 9.59894C9.20294 10.3764 9.82464 10.9974 10.6027 11.304L10.6891 11.3383L10.7143 11.2488C10.7664 11.0631 10.8706 10.8963 11.0153 10.7657C11.0257 10.7559 11.0398 10.751 11.0539 10.751C11.057 10.751 11.0601 10.751 11.0637 10.7516L12.5554 10.9883C12.5732 10.9913 12.5879 11.0011 12.5971 11.0165C12.6584 11.1225 12.6995 11.2384 12.7204 11.3598C12.7302 11.4138 12.7345 11.4708 12.7345 11.5302C12.7345 12.0974 12.2728 12.559 11.705 12.5597Z"
        fill="white"
      />
      <path
        d="M15.6769 9.50868C15.5561 8.14389 14.9308 6.87782 13.9161 5.94405C12.8953 5.00477 11.5709 4.4873 10.1859 4.4873C7.14611 4.4873 4.67283 6.96059 4.67283 10.0004C4.67283 11.0206 4.95424 12.0145 5.48704 12.8802L4.29883 15.5104L8.10318 15.1051C8.76472 15.3761 9.46489 15.5135 10.1853 15.5135C10.3747 15.5135 10.5691 15.5037 10.7641 15.4834C10.9357 15.465 11.1093 15.4381 11.2797 15.4037C13.8253 14.8893 15.6837 12.63 15.6984 10.0298V10.0004C15.6984 9.83485 15.691 9.66931 15.6763 9.50929L15.6769 9.50868ZM8.24971 13.9507L6.14491 14.1751L6.77335 12.7827L6.64766 12.6141C6.63846 12.6018 6.62926 12.5896 6.61884 12.5755C6.07317 11.8219 5.78501 10.9317 5.78501 10.001C5.78501 7.57431 7.75922 5.6001 10.1859 5.6001C12.4593 5.6001 14.3863 7.37383 14.5721 9.63804C14.5819 9.75944 14.5874 9.88145 14.5874 10.0016C14.5874 10.036 14.5868 10.0697 14.5862 10.1058C14.5396 12.1359 13.1215 13.8599 11.1375 14.2989C10.986 14.3326 10.8309 14.3584 10.6764 14.3749C10.5158 14.3933 10.3508 14.4025 10.1871 14.4025C9.60407 14.4025 9.03756 14.2897 8.50231 14.0665C8.44284 14.0426 8.38459 14.0169 8.33003 13.9905L8.25033 13.9519L8.24971 13.9507Z"
        fill="white"
      />
    </svg>
  );
};

export { WhatsApp };
